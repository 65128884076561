import other from './other';
import settings from './settings';
import reminders from './reminders';

// -----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
  items: [other, reminders, settings],
};

export default menuItems;
