import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS } from 'queries/utils/queries';

const FeatureFlagsContext = createContext({});

/**
 * @param children
 * @returns {JSX.Element}
 *
 * Stores PreceptorLink feature flags
 */
export const FeatureFlagsProvider = ({ children }) => {
  const [state, setState] = useState(undefined);

  const { data: { featureFlags = [] } = { featureFlags: [] } } = useQuery(FEATURE_FLAGS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let data = {};
    featureFlags?.forEach(flag => {
      data = { ...data, [flag.name]: flag.enabled };
    });
    setState(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(featureFlags)]);

  return (
    <FeatureFlagsContext.Provider value={{ state }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
