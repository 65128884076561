import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

// routing
import client from 'client';
import { ApolloProvider } from '@apollo/client';
import Routes from 'routes';

// defaultTheme
// eslint-disable-next-line import/no-named-as-default
import theme from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from './ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';

// auth providers
import { FirebaseProvider } from 'contexts/FirebaseContext';
// import {JWTProvider} from '../contexts/JWTContext';
// import {Auth0Provider} from '../contexts/Auth0Context';
import { UserProvider } from 'contexts/UserContext';
import { DataProvider } from 'contexts/DataContext';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { FeatureFlagsProvider } from './contexts/FeatureFlagsContext';

// -----------------------|| APP ||-----------------------//

const App = () => {
  const customization = useSelector(state => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>
            <ApolloProvider client={client}>
              <FirebaseProvider>
                <DataProvider>
                  <UserProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FeatureFlagsProvider>
                        <Routes />
                        <Snackbar />
                      </FeatureFlagsProvider>
                    </LocalizationProvider>
                  </UserProvider>
                </DataProvider>
              </FirebaseProvider>
            </ApolloProvider>
          </NavigationScroll>
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
