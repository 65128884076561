import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import { useBackendUserState } from 'contexts/UserContext';
import NavGroup from './NavGroup';
import menuItems from '../../../../menu-items';

// -----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
  const { state: { user: backendUser } } = useBackendUserState();
  const isSuperuser = backendUser?.isSuperuser;

  const filteredMenuItems = menuItems.items.filter(item => {
    if (!item.isSuperuser) {
      return true;
    }
    return item.isSuperuser && isSuperuser;
  });

  return filteredMenuItems.map(item => {
    if (item.type === 'group') {
      return <NavGroup key={item.id} item={item} />;
    }

    return (
      <Typography key={item.id} variant="h6" color="error" align="center">
        Menu Items Error
      </Typography>
    );
  });
};

export default MenuList;
