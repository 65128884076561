import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// project imports
import useAuth from 'hooks/useAuth';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3e4c5b3fcac801fcc553c77e1c5791b6@o4506189134888960.ingest.sentry.io/4506200663130112',
    replaysSessionSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [''],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
  });
}
// -----------------------|| AUTH GUARD ||----------------------- //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, user, logout } = useAuth();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && user?.email) {
      Sentry.setUser({
        email: user?.email,
      });
    }
  }, [user]);

  if (!isLoggedIn) {
    return <Redirect to="/login/" />;
  } if (user?.email && !/preceptorlink\.com|productera\.io|inveritasoft\.com|keplercode\.com|mccomasconstruction/.test(user?.email)) {
    logout();
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
