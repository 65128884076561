import React, { createContext, useEffect, useReducer } from 'react';
import { useMutation } from '@apollo/client';

// third-party
import firebase from 'firebase/app';
import 'firebase/auth';

// mutations
import { USER_LOGGED_IN_MUTATION, USER_LOGGED_OUT_MUTATION } from 'queries/account/mutation';

// action - state management
import { FIREBASE_STATE_CHANGED } from '../store/actions';

// project imports
import Loader from '../ui-component/Loader';
import config from '../config';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

// reducer - state management
const reducer = (state, action) => {
  switch (action.type) {
    case FIREBASE_STATE_CHANGED: {
      const {
        isLoggedIn,
        user,
      } = action.payload;

      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// -----------------------|| FIREBASE CONTEXT & PROVIDER ||-----------------------//

const FirebaseContext = createContext({
  ...initialState,
  firebaseEmailPasswordNewUser: () => Promise.resolve(),
  firebaseEmailPasswordSignIn: () => Promise.resolve(),
  firebaseGoogleSignIn: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// eslint-disable-next-line react/prop-types
export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userLoggedIn] = useMutation(USER_LOGGED_IN_MUTATION);
  const [userLoggedOut] = useMutation(USER_LOGGED_OUT_MUTATION);

  const firebaseEmailPasswordSignIn = (email, password) => firebase.auth()
    .signInWithEmailAndPassword(email, password).then(() => {
      userLoggedIn({ variables: { description: 'to the "Admin Panel"' } });
    });

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth()
      .signInWithPopup(provider).then(() => {
        userLoggedIn({ variables: { description: 'to the "Admin Panel" using a Google account' } });
      });
  };

  const firebaseEmailPasswordNewUser = async (email, password) => firebase.auth()
    .createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth()
    .signOut().then(() => {
      userLoggedOut({ variables: { firebaseUid: state.user?.id, description: 'from the "Admin Panel"' } });
    });

  useEffect(() => firebase.auth()
    .onAuthStateChanged(user => {
      if (user) {
        dispatch({
          type: FIREBASE_STATE_CHANGED,
          payload: {
            isLoggedIn: true,
            user: {
              id: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoUrl: user.photoURL,
            },
          },
        });
      } else {
        dispatch({
          type: FIREBASE_STATE_CHANGED,
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    }), [dispatch]);

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseEmailPasswordNewUser,
        firebaseEmailPasswordSignIn,
        firebaseGoogleSignIn,
        logout,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
