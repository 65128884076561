import {
  SelectAll as SelectAllIcon, Settings as SettingsIcon,
  Flare as FlareIcon, PersonPinCircle as PersonPinCircleIcon,
} from '@material-ui/icons';
// third-party
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import paths from 'paths';

// constant

// -----------------------|| SETTINGS MENU ITEMS ||-----------------------//

// eslint-disable-next-line import/prefer-default-export
export default {
  id: 'settings',
  title: 'settings',
  type: 'group',
  isSuperuser: true,
  children: [
    {
      id: 'general',
      title: 'General',
      type: 'item',
      url: paths.settingsGeneral.reverse(),
      icon: SettingsIcon,
      breadcrumbs: false,
    },
    {
      id: 'customFields',
      title: 'Custom Fields',
      type: 'item',
      url: paths.settingsFieldsListView.reverse(),
      icon: SelectAllIcon,
      breadcrumbs: false,
    },
    {
      id: 'wizards',
      title: 'Profiles',
      type: 'item',
      url: paths.settingsWizardListView.reverse(),
      icon: FlareIcon,
      breadcrumbs: false,
    },
    {
      id: 'tags',
      title: 'Tags',
      type: 'item',
      url: paths.settingsTags.reverse(),
      icon: LocalOfferIcon,
      breadcrumbs: false,
    },
    {
      id: 'state-ownership',
      title: 'State Ownership',
      type: 'item',
      url: paths.settingsStateOwnership.reverse(),
      icon: PersonPinCircleIcon,
      breadcrumbs: false,
    },
  ],
};
