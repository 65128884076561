import React, {
  createContext, useReducer, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ME } from 'queries/account/queries';
import useAuth from 'hooks/useAuth';

const SET_USER = 'SET_USER';
const userData = { user: {} };
const UserContext = createContext({});

const userStateReducer = (state, action) => {
  if (action.type === SET_USER) {
    return { ...state, user: action.payload };
  }
  return state;
};

/**
 * @param children
 * @returns {JSX.Element}
 *
 * Stores PreceptorLink user
 */
export const UserProvider = ({ children }) => {
  const { isLoggedIn, logout } = useAuth();
  const [state, dispatch] = useReducer(userStateReducer, userData);

  const { loading, data: { me } = {} } = useQuery(ME, {
    fetchPolicy: 'network-only', skip: !isLoggedIn,
  });

  useEffect(() => {
    if (!loading && me?.isStaff === false) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    dispatch({ type: SET_USER, payload: me });
  }, [me]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBackendUserState = () => useContext(UserContext);
