import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import paths from 'paths';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// sample page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));

const UserPage = Loadable(lazy(() => import('views/user')));

const RotationsListViewPage = Loadable(lazy(() => import('views/rotations/ListView')));
const RotationDetailsPage = Loadable(lazy(() => import('views/rotations/DetailView')));

// const DatabasePage = Loadable(lazy(() => import('views/database')));
const DatabasePreceptorListPage = Loadable(lazy(() => import('views/database/PreceptorListView')));
const DatabaseStudentListPage = Loadable(lazy(() => import('views/database/StudentListView')));
const DatabaseStudentPage = Loadable(lazy(() => import('views/database/StudentView')));
const DatabasePreceptorPage = Loadable(lazy(() => import('views/database/PreceptorView')));

const RequestsPage = Loadable(lazy(() => import('views/requests/ListView')));
const RequestPage = Loadable(lazy(() => import('views/requests/DetailView')));

const ReportsPage = Loadable(lazy(() => import('views/reports')));

const RemindersPage = Loadable(lazy(() => import('views/reminders')));

const SettingsGeneralPage = Loadable(lazy(() => import('views/settings/general/')));

const SettingsFieldsListViewPage = Loadable(lazy(() => import('views/settings/fields/ListView')));

const SettingsWizardsListViewPage = Loadable(lazy(() => import('views/settings/wizards/ListView')));
const SettingsWizardDetailViewPage = Loadable(lazy(() => import('views/settings/wizards/DetailView')));

const SettingsTagsPage = Loadable(lazy(() => import('views/settings/tags')));

const SettingsStateOwnershipPage = Loadable(lazy(() => import('views/settings/stateOwnership')));

// -----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        paths.dashboard.pattern,

        paths.user.pattern,

        paths.rotations.pattern,
        paths.rotationDetails.pattern,

        // paths.database.pattern,
        paths.databaseStudentView.pattern,
        paths.databasePreceptorView.pattern,
        paths.preceptors.pattern,
        paths.students.pattern,

        paths.preceptorsOld.pattern,
        paths.studentsOld.pattern,
        paths.databaseStudentViewOld.pattern,
        paths.databasePreceptorViewOld.pattern,

        paths.requests.pattern,
        paths.request.pattern,

        paths.reports.pattern,

        paths.reminders.pattern,

        paths.settingsGeneral.pattern,

        paths.settingsFieldsListView.pattern,

        paths.settingsWizardListView.pattern,
        paths.settingsWizardDetailView.pattern,

        paths.settingsTags.pattern,

        paths.settingsStateOwnership.pattern,
      ]}
    >
      <MainLayout showBreadcrumb>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route path={paths.dashboard.pattern} exact component={DashboardPage} />

            <Route path={paths.user.pattern} exact component={UserPage} />

            <Route path={paths.rotations.pattern} exact component={RotationsListViewPage} />
            <Route path={paths.rotationDetails.pattern} exact component={RotationDetailsPage} />

            {/* <Route path={paths.database.pattern} exact component={DatabasePage} /> */}
            <Route path={paths.preceptorsOld.pattern} exact component={DatabasePreceptorListPage} />
            <Route path={paths.studentsOld.pattern} exact component={DatabaseStudentListPage} />
            <Route path={paths.preceptors.pattern} exact component={DatabasePreceptorListPage} />
            <Route path={paths.students.pattern} exact component={DatabaseStudentListPage} />
            <Route path={paths.databaseStudentViewOld.pattern} exact component={DatabaseStudentPage} />
            <Route path={paths.databasePreceptorViewOld.pattern} exact component={DatabasePreceptorPage} />
            <Route path={paths.databaseStudentView.pattern} exact component={DatabaseStudentPage} />
            <Route path={paths.databasePreceptorView.pattern} exact component={DatabasePreceptorPage} />

            <Route path={paths.requests.pattern} exact component={RequestsPage} />
            <Route path={paths.request.pattern} exact component={RequestPage} />

            <Route path={paths.reports.pattern} exact component={ReportsPage} />

            <Route path={paths.reminders.pattern} exact component={RemindersPage} />

            <Route path={paths.settingsGeneral.pattern} exact component={SettingsGeneralPage} />

            <Route path={paths.settingsFieldsListView.pattern} exact component={SettingsFieldsListViewPage} />

            <Route path={paths.settingsWizardListView.pattern} exact component={SettingsWizardsListViewPage} />
            <Route path={paths.settingsWizardDetailView.pattern} exact component={SettingsWizardDetailViewPage} />

            <Route path={paths.settingsTags.pattern} exact component={SettingsTagsPage} />

            <Route path={paths.settingsStateOwnership.pattern} exact component={SettingsStateOwnershipPage} />
          </AuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
