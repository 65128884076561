import { gql } from '@apollo/client/core';

export const DATA = gql`
  query data {
    data {
      specialty {
        key
        value
        group
      }
      credentials {
        key
        value
      }
      typeOfPractice {
        key
        value
      }
      programOfStudy {
        key
        value
      }
      university2 {
        key
        value
      }
      ifYouSeePsychPatientsDoYouDo {
        key
        value
      }
    }
  }
`;

export const FEATURE_FLAGS = gql`
  query featureFlags {
    featureFlags {
      name
      enabled
    }
  }
`;
