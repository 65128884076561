import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DATA } from 'queries/utils/queries';
import { ALL_CLINIC_COORDINATORS } from '../queries/database-group/database-queries';
import { setClinicCoordinators } from '../store/actions';

const DataContext = createContext({});

/**
 * @param children
 * @returns {JSX.Element}
 *
 * Stores PreceptorLink backend data
 */
export const DataProvider = ({ children }) => {
  const [state, setState] = useState(undefined);

  const { data: { data } = {} } = useQuery(DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setState(data);
  }, [data]);

  const dispatch = useDispatch();
  const { data: { allClinicCoordinators = [] } = {} } = useQuery(ALL_CLINIC_COORDINATORS);

  const memoizedClinicCoordinators = useMemo(() => allClinicCoordinators, [allClinicCoordinators]);

  useEffect(() => {
    if (memoizedClinicCoordinators.length > 0) {
      dispatch(setClinicCoordinators(memoizedClinicCoordinators));
    }
  }, [memoizedClinicCoordinators, dispatch]);

  return (
    <DataContext.Provider value={{ state }}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBackendDataState = () => useContext(DataContext);
