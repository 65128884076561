import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// third-party
import { IntlProvider } from 'react-intl';

// load locales files
const loadLocaleData = locale => {
  switch (locale) {
    case 'fr':
      return import('../utils/locales/fr.json');
    case 'ro':
      return import('../utils/locales/ro.json');
    case 'zh':
      return import('../utils/locales/zh.json');
    default:
      return import('../utils/locales/en.json');
  }
};

// -----------------------|| LOCALIZATION ||-----------------------//

const Locales = ({ children }) => {
  const customization = useSelector(state => state.customization);
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(customization.locale)
      .then(d => {
        setMessages(d.default);
      });
  }, [customization.locale]);

  return (
    <React.Fragment>
      {messages && (
        <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </React.Fragment>
  );
};

Locales.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default Locales;
