import firebase from 'firebase/app';
import {
  ApolloClient, ApolloLink, InMemoryCache, from,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import * as Sentry from '@sentry/react';
// import { SentryLink } from 'apollo-link-sentry';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const authLink = setContext(async (_, { headers }) => {
  await sleep(2000);
  if (!firebase.auth().currentUser) {
    return { headers: { ...headers, authorization: '' } };
  }
  const token = await firebase.auth().currentUser.getIdToken(true);
  return { headers: { ...headers, authorization: token } };
});

const loggerLink = new ApolloLink((operation, forward) => (
  forward(operation).map(response => {
    // Capture the response as a breadcrumb in Sentry because it is not captured in network tab
    // due to bug either in apollo client or sentry
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `GraphQL ${operation.operationName} Response`,
      data: {
        response: response.data,
        operationName: operation.operationName,
        variables: operation.variables,
      },
    });
    return response;
  })
));

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const criticalErrors = [
      'Error decoding signature',
      'Signature has expired',
      'User is disabled',
    ];
    // eslint-disable-next-line no-unused-vars
    graphQLErrors.forEach(({ message }) => {
      if (criticalErrors.includes(message)) {
        window.localStorage.clear();
        const locationParams = `${window.location.pathname}${window.location.search}`;
        window.location.href = `/accounts/login/?next=${locationParams}`;
      }
    });
    // Sentry.captureException(JSON.stringify({ token, graphQLErrors }));
  }
  if (networkError) {
    // Sentry.captureException(JSON.stringify({ token, networkError }));
  }
});

// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
// });

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_APOLLO_CLIENT_URI });
// const sentryLink = new SentryLink();

const client = new ApolloClient({
  // uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  link: from([authLink, loggerLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
});

export default client;
