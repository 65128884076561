/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Card, CardContent, CardHeader, Divider, Typography,
} from '@material-ui/core';

// -----------------------|| CUSTOM SUB CARD ||-----------------------//

const SubCard = ({
  children,
  content,
  contentClass,
  darkTitle,
  secondary,
  sx = {},
  headerSX = {},
  title,
  subheader,
  ...others
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.grey[300],
        ':hover': {
          boxShadow: theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)',
        },
        ...sx,
      }}
      {...others}
    >
      {/* card header and action */}
      {!darkTitle && title
      && (
      <CardHeader
        sx={{ p: 2.5, ...headerSX }}
        title={<Typography variant="h5">{title}</Typography>}
        action={secondary}
        subheader={subheader}
      />
      )}
      {darkTitle && title
      && (
      <CardHeader
        sx={{ p: 2.5, ...headerSX }}
        title={<Typography variant="h4">{title}</Typography>}
        action={secondary}
        subheader={subheader}
      />
      )}

      {/* content & header divider */}
      {title && (
        <Divider
          sx={{
            opacity: 1,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.primary.light,
          }}
        />
      )}

      {/* card content */}
      {content && (
        <CardContent sx={{ p: 2.5 }} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
};

SubCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  headerSX: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
};

SubCard.defaultProps = {
  content: true,
  subheader: null,
};

export default SubCard;
