/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from '../../../config';
import Logo from '../../../ui-component/Logo';

// -----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <img
      style={{ maxWidth: '90%' }}
      src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-green.svg?alt=media&token=1e42ce43-58a4-4d47-937c-c92ee4ace385"
      alt=""
    />
  </ButtonBase>
);

export default LogoSection;
