import React from 'react';

// material-ui
// import { useTheme } from '@material-ui/core/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

// -----------------------|| LOGO SVG ||-----------------------//

const Logo = () => (
  <img src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-green.svg?alt=media&token=1e42ce43-58a4-4d47-937c-c92ee4ace385" alt="Berry" width="150" />
);

export default Logo;
