import PropTypes from 'prop-types';
import React from 'react';

// project imports
import Customization from '../Customization';

// -----------------------|| MINIMAL LAYOUT ||-----------------------//

const MinimalLayout = ({ children }) => (
  <React.Fragment>
    {children}
    <Customization />
  </React.Fragment>
);

MinimalLayout.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default MinimalLayout;
